<template>
  <div class=" wrapper" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')"
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header">
      <h1>{{ $t('organisation_list') }} </h1>
    </div>
    <div class="wp-body mha">
      <header class="wp-header">
        <div class="grid gap-3 grid-cols-12 flex-1">
          <div v-if="['admin', 'ministry'].includes(role)" class="col-span-3">
            <el-input class="w-full" :placeholder="$t('search')" v-model="search" @input="SearchAll()">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
        </div>
        <button class="button dark-yellow" @click="change_organization('new', 'new')"><i class="el-icon-plus mr-2"></i>{{ $t('add') }}</button>
      </header>
      <div class="w-full">
        <div v-if="['ministry', 'admin'].includes(role)" class="w-full flex fw-w" >
                <el-tag class="org-list-button" :class="{ 'active': catlist == item.id }" type="info" v-for="item in CatList"
                  :key="item.id" @click="$router.push(`/org-list/${item.id}`)"
                  style="margin:5px 5px 0 0; cursor:pointer;">{{ item[`name_${$i18n.locale}`] ?
                    item[`name_${$i18n.locale}`] : item.name_cyr}}

                </el-tag>
              </div>
              <div class="w-full flex fw-w" v-else>
                <el-tag class="org-list-button active"
                  style="margin:5px 5px 0 0; cursor:pointer;">{{ me.organization[`name_${$i18n.locale}`] ?
                    me.organization[`name_${$i18n.locale}`] : me.organization.name_cyr }}</el-tag>
              </div>
      </div>
    </div>

    <div class=" wp-body mha" style="margin-top:20px;">
      <el-table v-if="search != ''" :data="orgList2" style="width: 99%; margin-bottom: 20px;" row-key="id">
        <el-table-column :prop="`name_${$i18n.locale}` ? `name_${$i18n.locale}` : `name_cyr`"
          :label="$t('organisation_name')" sortable>
          <template slot-scope="scope">
            <router-link v-if="scope.row.generalinformation_num"
              :to="`/objects?id=${scope.row.id}&name_cyr=${scope.row.name_cyr}`" class="router-link">{{
                scope.row[`name_${$i18n.locale}`] ? scope.row[`name_${$i18n.locale}`] : scope.row.name_cyr }} <i
                class="el-icon-top-right"></i> </router-link>
            <p class="router-link" style="margin:0;"> {{ scope.row[`name_${$i18n.locale}`] ?
              scope.row[`name_${$i18n.locale}`] : scope.row.name_cyr }}</p>

          </template>
        </el-table-column>
        <el-table-column prop="generalinformation_num" :label="$t('objeck_count')" sortable width="200" align="center">
        </el-table-column>
        <el-table-column prop="children_num" :label="$t('organisation_count')" sortable width="200" align="center">
        </el-table-column>
        <el-table-column :label="$t('see')" width="80" align="center">
          <template slot-scope="scope">
            <div class="show-child"><i v-if="scope.row.children_num"
                @click="$router.push(`/org-list/${$route.params.org}/${scope.row.id}`)" class="el-icon-view"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('add_user')" width="150" align="center" v-if="role == 'admin'">
          <template slot-scope="scope">
            <div class="show-child">
              <button v-if="scope.row.id" @click="OpenMoadl(scope.row.id, scope.row[`name_${$i18n.locale}`])"
                class="default-button">
                <img src="@/assets/img/icons/useradd.svg" alt="">
              </button>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('change')" width="150" align="center" v-if="role == 'admin' && me.status === true">
          <template slot-scope="scope">
            <div class="show-child">
              <button @click="change_organization(scope.row, 'edit', 'filtered')" v-if="scope.row.id"
                class="default-button">
                <i class="el-icon-edit"></i>
              </button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-else :data="orgList.children" style="width: 99%; margin-bottom: 20px;" row-key="id">
        <el-table-column :prop="`name_${$i18n.locale}` ? `name_${$i18n.locale}` : `name_cyr`"
          :label="$t('organisation_name')" sortable>
          <template slot-scope="scope">
            <router-link v-if="scope.row.generalinformation_num"
              :to="`/objects?id=${scope.row.id}&name_cyr=${scope.row.name_cyr}`" class="router-link">{{
                scope.row[`name_${$i18n.locale}`] ? scope.row[`name_${$i18n.locale}`] : scope.row.name_cyr }} <i
                class="el-icon-top-right"></i> </router-link>
            <p v-else class="router-link" style="margin:0;"> {{ scope.row[`name_${$i18n.locale}`] ?
              scope.row[`name_${$i18n.locale}`] : scope.row.name_cyr }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="generalinformation_num" :label="$t('objeck_count')" sortable width="200" align="center">
        </el-table-column>
        <el-table-column prop="children_num" :label="$t('organisation_count')" sortable width="200" align="center">

        </el-table-column>
        <el-table-column :label="$t('see')" width="80" align="center">
          <template slot-scope="scope">
            <div class="show-child"><i v-if="scope.row.children_num"
                @click="$router.push(`/org-list/${$route.params.org}/${scope.row.id}`)" class="el-icon-view"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('add_user')" width="150" align="center" v-if="role == 'admin' && me.status === true">
          <template slot-scope="scope">
            <div class="show-child">
              <button v-if="scope.row.id" @click="OpenMoadl(scope.row.id, scope.row[`name_${$i18n.locale}`])"
                class="default-button">
                <img src="@/assets/img/icons/useradd.svg" alt="">
              </button>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('change')" width="150" align="center" v-if="role == 'admin' && me.status === true">
          <template slot-scope="scope">
            <div class="show-child">
              <button @click="change_organization(scope.row, 'edit', 'full')" v-if="scope.row.id"
                class="default-button">
                <i class="el-icon-edit"></i>
              </button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="margin-left: auto; margin-top: auto" v-if="search != ''" class="ml-auto"
        :page-sizes="[15, 20, 50, 100]" :page-size="5" @current-change="paginate" @size-change="paginateSize"
        :current-page.sync="current_page" :hide-on-single-page="false" background layout="sizes, prev, pager, next"
        :page-count="page_count">
      </el-pagination>
    </div>
    <div class="user-add-modal" v-if="modal">
      <div class="user-add-cover"></div>
      <div class="user-add-modal-content">
        <div class="w-full" v-show="step == 1">
          <div class="user-modal-body">
            <h1>{{ $t('create_new_user') }}</h1>
            <el-form :model="User" ref="userAdd" :rules="UserRules">
              <div class="item">
                <el-form-item>
                  <p>{{ $t('role') }}</p>
                  <template>
                    <el-radio v-for="(item, i) in roles" :key="i" :id="`role_name`" :disabled="modalLoading"
                      v-model="User.role" class="prim" :label="item.id" border>
                      {{ $t(keyTitles[item.name]) }}
                    </el-radio>

                  </template>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item prop="login_name">
                  <p>{{ $t('login') }}</p>
                  <GenerateLogin v-model="User.login_name" :step="step" :disabled="modalLoading"
                    :id="User.organization" />
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item prop="password">
                  <p>{{ $t('password') }}</p>
                  <GeneratedPassword v-model="User.password" :step="step" :disabled="modalLoading" />
                </el-form-item>
              </div>
            </el-form>
          </div>

          <div class="flex justify-end gap-2 items-end mt-8">
            <button class="button black" @click="Clear" :disabled="modalLoading"> <i class="el-icon-close mr-1"></i>
              {{ $t('otmen') }} </button>
            <button class="button dark-blue" @click="UserSave" :disabled="modalLoading"> <span v-if="!modalLoading"><i
                  class="el-icon-check mr-1"></i> {{ $t('save') }}</span> <i v-else class="el-icon-loading"></i>
            </button>
          </div>
        </div>
        <div class="w-full" v-show="step == 2">
          <div class="user-modal-body">
            <h2> <i class="el-icon-success"></i></h2>
            <h1>{{ $t('create_user_success') }}</h1>
            <div class="user-item-list">
              <div class="user-data">
                <p class="user-info-title">{{ $t('login') }}:</p>
                <p> {{ User.login_name }}</p>
                <button @click="Copy(User.login_name)"><i class="el-icon-document-copy"></i> </button>
              </div>
              <div class="user-data">
                <p class="user-info-title"> {{ $t('password') }}: </p>
                <p> {{ User.password }}</p>
                <button @click="Copy(User.password)"> <i class="el-icon-document-copy"></i> </button>
              </div>
              <div class="user-data">
                <p class="user-info-title">{{ $t('role') }}:</p>
                <p> {{ roleNames[getRoleName(User.role)] }} </p>
              </div>
              <div class="user-data">
                <p class="user-info-title"> {{ $t('organisation_name') }}:</p>
                <p>{{ organization_name }}</p>
              </div>
            </div>

          </div>
          <div class="flex justify-end gap-2 items-end mt-8">
            <button class="button black" @click="CopyAll()"> <i class="el-icon-document-copy"></i> </button>
            <button class="button dark-blue" @click="Clear"> {{ $t('close') }} </button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('change')" :visible.sync="dialogVisible" :close-on-press-escape="false">
      <el-form :model="OrgName" :rules="OrgRules" ref="org_position_edit">
        <div class="dialog__wrapper flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <span>{{ $t('org_name') }}</span>
            <el-form-item prop="name_cyr" :rules="[RuleRequired]">
              <el-input v-model="OrgName.name_cyr" :placeholder="$t('org_name')">
              </el-input>
            </el-form-item>
          </div>
          <div class="flex flex-col gap-2">
            <span>
              {{ $t('org_adress') }}
            </span>
            <el-form-item prop="state" :rules="[RuleRequired]">
              <el-autocomplete style="width: 100%;" clearable name="organization" valueKey="name_cyr"
                @blur="blurAutocomplete" v-model="OrgName.state" :fetch-suggestions="searchOrg" @select="handleSelect"
                :placeholder="$t('org_adress')">
              </el-autocomplete>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer"></span>
      <el-dialog width="40%" title="Огохлантириш" :visible.sync="innerVisible" append-to-body :close-on-press-escape="false"
        class="flex items-center justify-center">
        <div class="flex flex-col gap-3">
          <div class="flex justify-between mb-5">
            <span>
              {{ $t('building_count')  }}:
              {{ buildingsInfo && buildingsInfo.generalinformation }} {{ $t('ta') }}
            </span>
            <span>
              {{ $t('organisation_count')  }}:
              {{ buildingsInfo && buildingsInfo.specification }} {{  $t('ta') }}
            </span>
          </div>
          <span>{{ selectedOrg && selectedOrg.label }} {{ $t('org_change_sure') }}</span> 
          <div class="flex w-full justify-end gap-2 mt-5">
          <button class="button black" @click="innerVisible=false">{{ $t('otmen') }}</button>
          <button class="button dark-blue" @click="saveOrganization">{{ $t('save') }}</button>
        </div>
        </div>
      </el-dialog>
      <div slot="footer" class="flex items-center justify-end gap-2">
        <button class="button black" type="button" @click="dialogVisible = false">{{ $t('otmen') }}</button>
        <button class="button dark-blue" type="button" @click="confirmation">{{ $t('approve_btn') }}
        </button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('create_organization')" :visible.sync="dialogVisibleNew" :close-on-press-escape="false">
      <el-form :model="OrgName" :rules="OrgRules" ref="new_org">
        <div class="dialog__wrapper flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <span>{{ $t('org_name') }}</span>
            <el-form-item prop="name_cyr" :rules="[RuleRequired]">
              <el-input v-model="OrgName.name_cyr" :placeholder="$t('org_name')"
                @input="handleInput('name_cyr')"></el-input>
            </el-form-item>
          </div>
          <div class="flex flex-col gap-2">
            <span>{{ $t('org_adress') }}</span>
            <el-form-item prop="state" :rules="[RuleRequired]">
              <el-autocomplete style="width: 100%;" clearable valueKey="name_cyr" @blur="blurAutocomplete"
                v-model="OrgName.state" :fetch-suggestions="searchOrg" @select="handleSelect"
                @input="handleInput('state')" :placeholder="$t('org_adress')"></el-autocomplete>
            </el-form-item> 
          </div>
        </div>
      </el-form>
      <div slot="footer" class="flex justify-end gap-2">
        <button class="button black" type="button" @click="dialogVisibleNew = false">{{ $t('otmen') }}</button>
        <button class="button dark-blue" type="button" @click="validateForm">{{ $t('save') }}</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import GeneratedPassword from '../../components/PasswordGenerate.vue'
import GenerateLogin from '../../components/LoginGenerate.vue'
import _ from 'lodash';
export default {
  components: {
    GeneratedPassword,
    GenerateLogin
  },
  data() {
    return {
      loading: false,
      input2: null,
      search: '',
      catlist: 1,
      orgList: [],
      orgList2: [],
      CatList: [],
      page_count: 1,
      current_page: 1,
      item_count: 15,
      modal: false,
      step: 1,
      User: {
        login_name: "",
        organization: '',
        role: 3,
        password: ""
      },
      OrgRules: {
        name_cyr: [
          {
            required: true,
            message: this.$t('please_enter_data'),
            trigger: 'blur'
          }
        ],
        state: [
          {
            required: true,
            message: this.$t('please_enter_data'),
            trigger: 'blur'
          }
        ]
      },
      UserRules: {
        login_name: [{
          required: true,
          message: 'Илтимос фойдаланувчи номини киритинг!',
          trigger: 'change'
        },
        {
          min: 2,
          max: 25,
          message: 'Логин узунлиги 2 ва 25 орасида бўлиши керак!',
          trigger: 'change'
        }
        ],
        password: [{
          required: true,
          message: 'Илтимос паролни киритинг!',
          trigger: 'change'
        },
        {
          min: 8,
          max: 20,
          message: 'Парол узунлиги 8 ва 25 орасида бўлиши керак!',
          trigger: 'change'
        }
        ],
      },
      modalLoading: false,
      organization_name: 'Organisation Name',
      roles: [],
      roleNames: {
        tf_operator: 'ТИ Оператор',
        operator: 'Оператор',
        no: '--'
      },
      dialogVisible: false,
      dialogVisibleNew: false,
      OrgName: {
        name_cyr: '',
        state: '',
      },
      links: [],
      timeout: null,
      selectedOrg: null,
      buildingsInfo: null,
      organization: {
        id: null,
        perent: null
      },
      innerVisible: false,
      status: '',
      keyTitles: {
        operator: 'operator',
        tf_operator: 'tf_operator',
        org_admin: 'org_admin',
      },
      RuleRequired: {
        required: true,
        message: "Iltimos maydonni to'ldiring",
        trigger: 'change'
      },
    };
  },
  watch: {
    catlist: function () {
      this.__GET();
    },
    '$route.params.org': function (val) {
      this.catlist = val
    },
    me() {
      this.catlist = this.$route.params.id ? this.$route.params.id : this.me.organization.id
    },
    role() {
      if (this.$route.params.org == undefined) {
        if (this.role !== 'admin' && this.me.organization.id) {

          this.$router.push(`/org-list/${this.me.organization.id}`)
        } else {
          this.$router.push('/org-list/209')
        }

      }
      if (this.role == 'admin' || this.role == 'ministry') {
        this.catlist = this.$route.params.org ? this.$route.params.org : 209
        axios.get('/organization/?limit=15&level=1')
          .then((response) => {
            this.CatList = response.data.results;
          })
          .finally(() => {
            this.loading = false;
          })
      } else {
        this.catlist = this.$route.params.id ? this.$route.params.id : this.me.organization.id
      }
    }

  },
  computed: {
    me() {
      return this.$store.state.me;
    },
    role() {
      return this.$store.state.role;
    },

  },
  mounted() {
    this.querySearch = _.debounce(this.querySearch, 900)

    if (this.$route.params.org == undefined) {
      if (this.role !== 'admin' && this.me.organization.id) {

        this.$router.push(`/org-list/${this.me.organization.id}`)
      } else {
        this.$router.push('/org-list/209')
      }

    }
    if (this.role == 'admin' || this.role == 'ministry') {
      this.catlist = this.$route.params.org ? this.$route.params.org : 209
      axios.get('/organization/?limit=15&level=1')
        .then((response) => {
          this.CatList = response.data.results;
          // this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        })
    } else {
      this.catlist = this.$route.params.id ? this.$route.params.id : this.me.organization.id

      // this.catlist =this.me.organization.id
    }
    this._Getroles()
    // this.getTree()

  },
  methods: {
    confirmation() {
      this.$refs.org_position_edit.validate((valid) => {
        if (valid) {
          this.innerVisible = true
        }
      })
    },
    blurAutocomplete() {
      setTimeout(() => {
        if (!this.organization.id) {
          this.OrgName.state = ''
        }
      },1000)
    },
    handleInput(prop) {
      this.$refs.new_org.validateField(prop)
    },
    validateForm() {
      this.$refs.new_org.validate((valid) => {
        if (valid) {
          this.createNewOrganization()
        }
      })
    },
    createNewOrganization() {
      this.$refs.new_org.validate((valid) => {
        if (valid) {
          const data = {
            parent: this.organization.id,
            name_cyr: this.OrgName.name_cyr
          }

          const cleaned_data = {}

          for (const option in data) {
            if (data[option] !== "" && data[option] !== null) {
              cleaned_data[option] = data[option]
            }
          }
          axios.post(`organization/`, cleaned_data)
            .then(() => {
              this.__GET()
              this.dialogVisibleNew = false
            })
        }
      })
    },
    searchOrg(queryString, cb) {
      this.querySearch(queryString, cb)
    },
    querySearch(queryString, cb) {
      const params = {
        limit: 200,
        search: queryString || ''
      }
      axios.get(`/organization/`, { params })
        .then((response) => {
          cb(response.data.results)
        })
    },
    saveOrganization() {
      const data = {
        id: this.OrgName.id,
        parent: this.organization.id,
        name_cyr: this.OrgName.name_cyr
      }

      const cleaned_data = {}

      for (const option in data) {
        if (data[option] !== "" && data[option] !== null) {
          cleaned_data[option] = data[option]
        }
      }
      axios.patch(`organization/${this.OrgName.id}/`, cleaned_data)
        .then(() => {
          this.__GET()
          this.innerVisible = false
          this.dialogVisible = false
        })
    },
    handleSelect(item) {  
      this.organization.id = item.id
      if(item.parent){
        this.organization.parent = item.parent
      } 
     
    },
    createFilter(queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    async change_organization(data, status, type) {
      if (this.$refs.org_position_edit) {
        this.$refs.org_position_edit.resetFields();
      }
      if (this.$refs.new_org) {
        this.$refs.new_org.resetFields();
      }
      this.OrgName.state = ''
      this.organization = {
        id: null,
        perent: null
      }
      this.status = status

      if (status == 'new', data == 'new') {

        this.OrgName = {
          name_cyr: ''
        }
        this.dialogVisibleNew = true
      } else if (status == 'edit') {
        await axios
          .get(`/organization/get_generalinfo/?id=${data.id}`)
          .then((res) => {
            this.buildingsInfo = res.data;
          })
        this.buildingsInfo.specification = data.children_num
        if (type == 'filtered') {
          this.OrgName = {
            ...this.orgList2.filter(item => item.id === data.id)[0],
            state: ''
          }
        } else if (type == 'full') {
          this.OrgName = {
            ...this.orgList.children.filter(item => item.id === data.id)[0],
            state: ''
          }
        }
        this.dialogVisible = true
      }


    },
    pushTreeComponent() {
      console.log(this.$route.params);
      this.$router.push({
        path: '/tree',
        query: {
          id: this.$route.params.org
        }
      })
    },
    paginate(val) {
      this.current_page = val;
      this.SearchAll()
    },
    paginateSize(val) {
      this.item_count = val
      this.SearchAll()
    },
    __GET() {
      if (this.catlist != null) {
        this.loading = true;
        axios.get(`/organization/${this.catlist}/`)
          .then((response) => {
            this.orgList = response.data;
          })
          .finally(() => {
            this.loading = false;
          })
      }

      this.search = ''
    },
    SearchAll() {
      this.loading = true;
      let offset = this.item_count * (this.current_page - 1);
      axios.get(`/organization/?limit=${this.item_count}&offset=${offset}&search=${this.search}`)
        .then((response) => {
          this.orgList2 = response.data.results;
          this.page_count = Math.ceil(response.data.count / this.item_count);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    OpenMoadl(id, name) {
      this.User.organization = id
      this.modal = true
      this.organization_name = name
    },
    Clear() {
      this.step = 1
      this.organization_name = '',
        this.$refs.userAdd.resetFields()
      this.User.role = this.roles[0].id
      this.modal = false
    },
    UserSave() {
      this.modalLoading = true
      axios.get(`/users?search=${this.User.login_name}`)
        .then((res) => {
          if (res.data.results.length < 1) {
            axios.post('/users/', this.User)
              .then(response => {
                this.modal = false
                this.step = 2
                this.$swal.fire({
                  icon: 'success',
                  title: this.$t('create_user_success'),
                  timer: 3000
                }).then(() => {
                  this.modal = true
                })

              })
              .finally(() => {
                this.modalLoading = false
              })
          } else {
            this.modalLoading = false
          }
        })

    },
    CopyAll() {
      var item = `Логин: ${this.User.login_name}, \nПароль: ${this.User.password}, \nТашкилот:${this.organization_name}`
      var a = this.$copyText(item)
      a ? this.$swal.fire({
        icon: 'success',
        title: this.$t('copy_success'),
        timer: 3000
      }) : this.$swal.fire({
        icon: 'error',
        title: this.$t('error_ser'),
        timer: 3000
      })
      this.Clear()
    },
    Copy(item) {
      var a = this.$copyText(item)
      a ? this.$swal.fire({
        icon: 'success',
        title: this.$t('copy_success'),
        timer: 3000
      }) : this.$swal.fire({
        icon: 'error',
        title: this.$t('error_ser'),
        timer: 3000
      })
    },
    _Getroles() {
      axios.get('/role/views').then(res => {
        this.roles = res.data
        this.User.role = res.data[0].id
      })
    },
    getRoleName(id) {
      let a = 'no'
      this.roles.forEach(element => {
        if (element.id == id) {
          a = element.name
        }
      })
      return a
    },

  }
};
</script>

<style lang="scss" scoped> 

tbody tr td:last-child {
  display: flex;
  justify-content: center;
}

ul {
  display: flex;
  margin-bottom: 20px;

}

.cast-items {
  min-width: 30%;
  padding: 5px;
  flex: 1;
}

.cast-item {
  width: 100%;
  padding: 20px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.show-child i {
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.user-add-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .user-add-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: 0;
  }

  .user-add-modal-content {
    max-width: 700px;
    width: 100%;
    background: #fff;
    z-index: 1;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    padding: 30px;
  }

  .user-add-modal-header {
    h1 {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .user-modal-body {
    h1 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      text-align: center;
    }

    h2 {
      font-size: 80px;
      text-align: center;
      color: green;
    }

    .item {
      p {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 5px;
      }

      .el-form-item {
        margin-bottom: 15px !important;
      }
    }

  } 

  .user-data {
    display: flex;
    align-items: center;
    padding: 5px 0px;

    .user-info-title {
      margin-right: 10px;
      min-width: 120px;
      font-weight: 600;
    }

    p {
      font-size: 14px;
    }

    button {
      margin-left: 10px;
      font-size: 14px;
      width: 30px;
      height: 30px;
    }
  }

}


.org-list-button { 
  // border: 1px solid #004787;
  border-radius: 10px;
  font-weight: 600;
  color: #004787;
  background: #fff;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.1);
  

  &.active {
    background: #004787;
    color: #fff;
    border: 1px solid #004787;
  }
}
</style>
